<template>
  <PageCard
    pageName="FIN Profile"
    pageType="Select Institution"
    :iconClass="['cuis-dashboard', 'card-icon-font']"
  >
    <template slot="page_content">
      <div v-if="canPurchase && isAnalyticsEnterpriseUser">
        <content-placeholders :rounded="true" v-if="loading">
          <content-placeholders-text :lines="15"></content-placeholders-text>
        </content-placeholders>
        <EnterpriseUserSelectInstitution
          reportType="profile"
          :purchasedInstitutions="institutions"
          :states="states"
          :currentSelectedInstitution="selectedInstitution"
          @institutionSelected="setSelectedInstitution"
          v-else
        ></EnterpriseUserSelectInstitution>
      </div>
      <b-row class="search-container--institution" v-else>
        <b-col sm="12">
          <div class="search-container--bar">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search fa-lg"></i>
                </span>
              </div>
              <b-form class="search-form">
                <b-form-input
                  autocomplete="off"
                  id="prependedInput"
                  class="form-control no-focus-highlt search-placeholder"
                  size="lg"
                  type="text"
                  placeholder="Search by FI Name, City, State, Federal Reserve ID, FDIC or ABA number . . ."
                  @keyup.native="search"
                  v-model="searchText"
                >
                </b-form-input>
              </b-form>
            </b-input-group>
          </div>
          <div
            class="search-container--result std-border"
            v-on:scroll.passive="fetchMoreData($event)"
          >
            <div>
              <content-placeholders :rounded="true" v-if="loading">
                <content-placeholders-text
                  :lines="15"
                ></content-placeholders-text>
              </content-placeholders>
              <div class="text-left" v-else>
                <b-list-group v-if="emptyInstitutions" class="text-center">
                  <b-list-group-item
                    ><i>No Institutions found ... </i></b-list-group-item
                  >
                </b-list-group>
                <b-list-group v-else>
                  <div v-for="ins in filteredInstitutions" :key="ins.id">
                    <b-list-group-item
                      @click="setSelectedInstitution(ins)"
                      :class="[
                        { selected: ins.id === selectedInstitution.id },
                        'p-2',
                        'pl-4'
                      ]"
                      :ref="'inst_' + ins.id"
                      :title="ins.alternate_names"
                    >
                      {{ ins.full_name }}, {{ ins.city }}, {{ ins.state }}
                    </b-list-group-item>
                  </div>
                </b-list-group>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="text-center pt-1">
        <b-col md="12">
          <b-button
            size=""
            variant="primary"
            @click="handlePurchase()"
            class="fw-btn mt-3 mx-3 px-2"
            :disabled="!canPurchase"
            v-if="!isAnalyticsEnterpriseUser"
            >Order New Institution</b-button
          >
          <router-link
            tag="button"
            :to="{ path: 'custom_profile' }"
            class="btn btn-primary fw-btn mt-3 mx-3"
            :disabled="!enableContinue"
          >
            Continue
          </router-link>
        </b-col>
      </b-row>
      <OrderBankDetails
        reportType="profile"
        :show="showModal"
        :purchasedInstitutions="institutions"
        @openAuthModal="openAuthModal"
        @closeModal="closeModal"
        @institutionUpdated="updateInstitution"
      ></OrderBankDetails>
      <UserAuthenticationModal
        :show="showAuthModal"
        @closeAuthModal="closeAuthModal"
        @loginSuccess="loginSuccess"
      ></UserAuthenticationModal>
    </template>
  </PageCard>
</template>

<script>
import store from '@/store/store'
import router from '@/router/index'
import _ from 'lodash'
import { mapState, mapGetters, mapMutations } from 'vuex'
import PageCard from '@/modules/core/components/layouts/PageCard'
import OrderBankDetails from '@/modules/core/components/orderBankDetails'
import UserAuthenticationModal from '@/modules/core/components/UserAuthenticationModal'
import EnterpriseUserSelectInstitution from '@/modules/core/components/EnterpriseUserSelectInstitution'
export default {
  name: 'Institution',
  components: {
    PageCard,
    OrderBankDetails,
    UserAuthenticationModal,
    EnterpriseUserSelectInstitution
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/showProfile']) {
      next(vm => {
        vm.setInstitution({})
        vm.getUserInstitutions()
      })
    } else {
      router.push('/analytics/about_profile')
    }
  },
  created() {
    document.title = 'FIN Profile'
  },
  data() {
    return {
      loading: false,
      searchText: '',
      institutions: [],
      filteredInstitutions: [],
      showModal: false,
      showAuthModal: false,
      states: [],
      offset: 0
    }
  },
  computed: {
    ...mapState(['storedInstitutions']),
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('Profile', ['selectedInstitution']),
    ...mapGetters('Ability', [
      'canPurchase',
      'isProfilePartiallyEnabled',
      'isAnalyticsEnterpriseUser'
    ]),
    enableContinue() {
      return (
        this.selectedInstitution !== undefined &&
        this.selectedInstitution.id !== undefined
      )
    },
    emptyInstitutions() {
      return this.filteredInstitutions.length === 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isProfilePartiallyEnabled) {
        this.$toasted.show(
          'Access to FIN Profile Digital Channels - Not applicable to BHCs ',
          {
            icon: 'info-circle',
            type: 'info',
            duration: 5000
          }
        )
      }
    })
  },
  methods: {
    ...mapMutations('Profile', ['setInstitution', 'setReportId']),
    fetchStates() {
      this.$http
        .get('/api/states', {
          handleErrors: true
        })
        .then(res => {
          this.states = res.data.states
        })
    },
    getUserInstitutions() {
      this.loading = true
      return this.$http
        .get('/api/advisors', {
          params: {
            search_text: this.searchText,
            offset: this.offset
          },
          handleErrors: true
        })
        .then(res => {
          if (this.states.length < 1) this.fetchStates()
          this.institutions = res.data.banks
          this.removeBHCsIfPartialAccess()
          this.filteredInstitutions = this.institutions
          this.$nextTick(function() {
            if (this.selectedInstitution && this.selectedInstitution.id) {
              let ref = this.$refs['inst_' + this.selectedInstitution.id]
              if (ref) {
                ref[0].scrollIntoView({ behavior: 'smooth' })
              }
            }
          })
        })
        .then(() => {
          this.loading = false
        })
    },
    fetchMoreData(event) {
      let el = event.target
      if (Math.round(el.scrollTop) >= el.scrollHeight - el.offsetHeight) {
        if (this.offset < this.filteredInstitutions.length) {
          this.loading = true
          this.offset = this.filteredInstitutions.length
          this.$http
            .get('/api/advisors', {
              params: {
                search_text: this.searchText,
                offset: this.offset
              },
              handleErrors: true
            })
            .then(res => {
              this.institutions = res.data.banks
              this.removeBHCsIfPartialAccess()
              this.filteredInstitutions.push(...this.institutions)
              this.$nextTick(function() {
                if (this.selectedInstitution && this.selectedInstitution.id) {
                  let ref = this.$refs['inst_' + this.selectedInstitution.id]
                  if (ref) {
                    ref[0].scrollIntoView({ behavior: 'smooth' })
                  }
                }
              })
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    },
    removeBHCsIfPartialAccess() {
      if (this.isProfilePartiallyEnabled) {
        this.institutions = this.institutions.filter(institution => {
          return institution.fi_type !== 'bhc'
        })
      }
    },
    updateInstitution: function(banks) {
      for (let bank of banks) {
        if (this.filteredInstitutions.map(x => x.id).indexOf(bank.id)) {
          this.filteredInstitutions.unshift(bank)
        }
      }
      this.institutions = this.filteredInstitutions
      this.removeBHCsIfPartialAccess()
      //this.filteredInstitutions = this.institutions
    },
    handlePurchase: function() {
      this.showModal = true
    },
    closeModal: function() {
      this.showModal = false
    },
    search: _.debounce(function(event) {
      this.searchInstitutions(event)
    }, 500),

    searchInstitutions: function(event) {
      let text = event.target.value.toLowerCase()
      this.searchText = text
      this.offset = 0
      this.filteredInstitutions = []
      this.setInstitution({})
      this.getUserInstitutions()
    },
    setSelectedInstitution: function(institution) {
      this.searchText = institution.name
      this.setInstitution({
        id: institution.id,
        name: institution.name,
        fiType: institution.fi_type
      })
      this.setReportId(null)
    },
    loginSuccess() {
      this.getUserInstitutions().then(() => {
        this.closeAuthModal()
        this.showModal = true
      })
    },
    closeAuthModal() {
      this.showAuthModal = false
    },
    openAuthModal() {
      this.showModal = false
      this.showAuthModal = true
    }
  }
}
</script>

<style lang="scss" scoped></style>
